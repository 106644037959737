import React from "react";
import { Navigate } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import PhoneList from "./pages/phones/PhoneList";
import PhoneDetailPage from "./pages/phones/PhoneDetailPage";
import RepairServices from "./pages/repair/RepairServicesPage";
import ModeDeReparation from "./pages/repair/ModeDeReparation";
import ModeEnLigne from "./pages/repair/ModeEnLigne";
import ModeInShop from "./pages/repair/ModeInShop";
import Inscription from "./pages/user/Inscription";
import BrandsList from "./pages/brands/BrandsList";
import ContactPage from "./pages/ContactPage";
import AccessPage from "./pages/user/AccessPage";
import PaymentPage from "./pages/PaymentPage";
import CreateRepairPage from "./pages/repair/CreateRepairPage";
import ConfirmationPage from "./pages/repair/ConfirmationPage";
import RepairServicesList from "./pages/repair/RepairServicesList";
import About from "./pages/About";
import AccountPage from "./pages/Custumer/AccountPage";
import AccountOverview from "./pages/Custumer/AccountOverview";
import AccountDetails from "./pages/Custumer/AccountDetails";
import RepairHistory from "./pages/Custumer/RepairHistory";
import Logout from "./pages/Custumer/Logout";

const RoutesConfig = (ProtectedRoute) => {
  return [
    { path: "/", element: <HomePage /> },
    { path: "/repair/:brand", element: <PhoneList /> },
    { path: "/phone-detail/:model", element: <PhoneDetailPage /> },
    { path: "/repair-services", element: <RepairServices /> },
    { path: "/mode-de-reparation", element: <ModeDeReparation /> },
    { path: "/mode-en-ligne", element: <ModeEnLigne /> },
    { path: "/mode-in-shop", element: <ModeInShop /> },
    { path: "/inscription", element: <Inscription /> },
    { path: "/repair", element: <BrandsList /> },
    { path: "/contact", element: <ContactPage /> },
    { path: "/acces", element: <AccessPage /> },
    { path: "/payment", element: <PaymentPage /> },
    { path: "/createrepairpage", element: <CreateRepairPage /> },
    { path: "/confirmation", element: <ConfirmationPage /> },
    { path: "/List-Repair-Services", element: <RepairServicesList /> },
    { path: "/about", element: <About /> },
    {
      path: "/account",
      element: (
        <ProtectedRoute>
          <AccountPage />
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <Navigate to="/account/account-overview" /> },
        { path: "account-overview", element: <AccountOverview /> },
        { path: "account-details", element: <AccountDetails /> },
        { path: "repair-history", element: <RepairHistory /> },
        { path: "logout", element: <Logout /> },
      ],
    },
  ];
};

export default RoutesConfig;
