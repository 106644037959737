import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { useClient } from '../repair/ClientContext';
import './AccountPage.scss';

const AccountPage = () => {
  const { token, isAuthenticated } = useAuth();
  const { clientData, setClientData, setLoading } = useClient();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(true);
  const [error, setError] = useState('');

  const fetchUserData = useCallback(async (token) => {
    setLoading(true);
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;

    try {
      const response = await fetch(
        `https://shoponlignebackend-production.up.railway.app/clients/search?userId=${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (!response.ok) throw new Error('Erreur lors de la récupération des données utilisateur');
      const data = await response.json();
      setClientData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setLoadingState(false);
    }
  }, [setClientData, setLoading]);

  useEffect(() => {
    if (!token) {
      setError('Aucun token disponible. Veuillez vous connecter.');
      setLoadingState(false);
      navigate('/acces');
      return;
    }

    if (isAuthenticated) {
      fetchUserData(token);
    } else {
      setError('Utilisateur non authentifié.');
      setLoadingState(false);
      navigate('/acces');
    }
  }, [token, isAuthenticated, navigate, fetchUserData]);

  if (loadingState) return <p>Chargement...</p>;

  if (error) {
    return (
      <Container className="account-page">
        <Row>
          <Col md={12}>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="account-page">
      <Row>
        <Col>
          <h2>Bienvenue, {clientData?.name || 'Utilisateur'}!</h2>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountPage;
