import { useRepair } from './RepairContext'; // Import du hook personnalisé
import { useClient } from './ClientContext'; // Importation du hook useClient

const CreateRepair = ({ onSuccess, onError }) => {
  const { repairData, updateRepairData } = useRepair(); // Inclut clearRepairData
  const { clientData } = useClient(); // Récupérer les données du client depuis le contexte

  const handleCreateRepair = async () => {
    console.log('Démarrage de la création de la réparation...');

    // Vérification des données nécessaires
    if (!repairData.repairType || !clientData?.id) {
      console.log('Données manquantes :', {
        repairType: repairData.repairType,
        clientId: clientData?.id || 'Aucun ID client',
      });
      alert('Veuillez remplir toutes les informations de réparation.');
      return;
    }

    // Préparer les données à envoyer à l'API
    const phoneModel = repairData.selectedPhone?.model || 'Modèle inconnu';
    

    const repairDataToSend = {
      clientId: clientData.id, // Assurez-vous que clientData contient un ID valide
      repair: {
        creationDate: new Date().toISOString(),
        status: 'CREATED',
        notes: `Mode: ${repairData.repairMode || 'Non spécifié'}, Téléphone: ${phoneModel || 'Modèle inconnu'}`,
        services: repairData.selectedServices || [], // Assurez-vous que services est un tableau
      },
    };
    
    // Vérifiez le payload avant l'envoi
    console.log('Payload envoyé :', JSON.stringify(repairDataToSend, null, 2));
    

    try {
      // Appel de l'API pour créer la réparation
      const response = await fetch(
        'https://shoponlignebackend-production.up.railway.app/repairs',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(repairDataToSend),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error('Erreur API :', errorResponse);
        throw new Error(errorResponse.message || 'Erreur lors de la création de la réparation');
      }

      const data = await response.json();
      console.log('Réparation créée avec succès, ID:', data.id);

      // Mettre à jour le repairId dans le contexte
      updateRepairData('repairId', data.id);

      // Appeler le callback de succès si défini
      if (onSuccess) onSuccess(data);

    } catch (error) {
      console.error('Erreur lors de la création de la réparation :', error);
      alert('Une erreur est survenue lors de la création de la réparation.');

      // Appeler le callback d'erreur si défini
      if (onError) onError(error);
    }
  };

  return { handleCreateRepair };
};

export default CreateRepair;
