import React, { useState } from 'react';
import TopMenu from './TopMenu';
import MainMenu from './MainMenu'; // Import du composant MainMenu
import AccountMenu from './AccountMenu';
import { useAuth } from './AuthContext';
import './HeaderDesktop.scss';

const HeaderDesktop = () => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const isAuthenticated = useAuth().isAuthenticated;

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const closeAccountMenu = () => {
    setIsAccountMenuOpen(false);
  };

  return (
    <header className="header-desktop">
      <div className="header-container">
        <div className="left-section">
          <TopMenu />
          <MainMenu /> {/* Ajout du MainMenu */}
        </div>
        <div className="right-section">
          {isAuthenticated && ( // Affiche "Compte" uniquement si authentifié
            <div className="hamburger-account-menu">
              <button className="hamburger-button" onClick={toggleAccountMenu}>
                ☰ Compte
              </button>
              {isAccountMenuOpen && (
                <div className="account-menu-popin">
                  <AccountMenu onClose={closeAccountMenu} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
