import React, { useEffect, useState } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRepair } from './RepairContext'; // Importer le contexte
import './RepairServices.scss';

const RepairServices = () => {
  const { repairData, updateRepairData } = useRepair();
  const [repairServices, setRepairServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (repairData.phoneId) {
      const cacheKey = `repairServices_${repairData.phoneId}`;
      const cacheExpiryKey = `repairServicesExpiry_${repairData.phoneId}`;
      const now = new Date().getTime();

      const cachedData = localStorage.getItem(cacheKey);
      const cachedExpiry = localStorage.getItem(cacheExpiryKey);

      if (cachedData && cachedExpiry && now < parseInt(cachedExpiry, 10)) {
        // Utiliser les données en cache
        setRepairServices(JSON.parse(cachedData));
      } else {
        // Fetch des nouvelles données depuis l'API
        fetch(`https://shoponlignebackend-production.up.railway.app/phones/repair-options?phoneId=${repairData.phoneId}`)
          .then((response) => response.json())
          .then((data) => {
            // Stockage des données dans le cache avec une durée de 24 heures
            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(cacheExpiryKey, (now + 24 * 60 * 60 * 1000).toString());
            setRepairServices(data);
          })
          .catch((error) => console.error('Error fetching repair services:', error));
      }
    }
  }, [repairData.phoneId]);

  const toggleServiceSelection = (serviceName) => {
    // Vérifie si le service est déjà sélectionné
    const updatedServices = repairData.selectedServices.includes(serviceName)
      ? repairData.selectedServices.filter((s) => s !== serviceName) // Supprime si déjà présent
      : [...repairData.selectedServices, serviceName]; // Ajoute si absent

    // Mise à jour du contexte avec la liste des services sélectionnés
    updateRepairData('selectedServices', updatedServices);
  };

  const toggleDescription = (serviceName) => {
    // Alterner l'affichage de la description
    updateRepairData(
      'expandedService',
      repairData.expandedService === serviceName ? null : serviceName
    );
  };

  const handleNextClick = () => {
    navigate('/mode-de-reparation', { state: { selectedServices: repairData.selectedServices } });
  };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <Container className="mt-3">
      <h1 className="text-center mb-3">Services de Réparation</h1>

      <Row className="g-3">
        {repairServices.length > 0 ? (
          repairServices.map((service) => (
            <Col xs={12} sm={6} lg={4} key={service.id}>
              <div className="service-card">
                <Form.Check
                  type="checkbox"
                  checked={repairData.selectedServices.includes(service.repairOption.name)}
                  onChange={() => toggleServiceSelection(service.repairOption.name)}
                  className="mb-3"
                />
                <h5 
                  className="service-name" 
                  onClick={() => toggleDescription(service.repairOption.name)}
                >
                  {service.repairOption.name}
                </h5>
                <p className="service-price">
                  {service.price === 0 ? 'Contactez-nous pour le prix' : `${service.price} €`}
                </p>
                {repairData.expandedService === service.repairOption.name && (
                  <p className="service-description">
                    {service.repairOption.description || "Pas de description disponible."}
                  </p>
                )}
              </div>
            </Col>
          ))
        ) : (
          <p className="text-center">
            Aucun service de réparation disponible pour ce modèle. Contactez-nous pour plus d'informations.
          </p>
        )}
      </Row>

      <Row className="mt-4">
        <Col xs={6} className="text-end">
          <button 
            className="btn-primary w-100" 
            onClick={handleBackClick}
          >
            Retour
          </button>
        </Col>
        <Col xs={6} className="text-start">
          <button 
            className="btn-primary w-100" 
            onClick={handleNextClick} 
            disabled={repairData.selectedServices.length === 0}
          >
            Suivant
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default RepairServices;
