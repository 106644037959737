import React from 'react';
import { Card, Badge, Spinner, Row, Col } from 'react-bootstrap';
import { useClient } from '../repair/ClientContext';
import './AccountOverview.scss';

const AccountOverview = () => {
  // Récupérer les données du client depuis le contexte
  const { clientData, loading } = useClient();

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p>Chargement des informations...</p>
      </div>
    );
  }

  const lastRepairs = clientData?.repairs?.slice(-10).reverse() || [];

  return (
    <div className="account-overview">
      <Card className="profile-section mb-4 shadow-sm">
        <Card.Body>
          {clientData ? (
            <Card.Text>
              <strong>Nom :</strong> {clientData.name}<br />
              <strong>Email :</strong> {clientData.email}<br />
              <strong>Téléphone :</strong> {clientData.phone}<br />
              <strong>Adresse :</strong> {clientData.address}, {clientData.city}, {clientData.postalCode}<br />
              <strong>Date de naissance :</strong> {new Date(clientData.dateOfBirth).toLocaleDateString()}
            </Card.Text>
          ) : (
            <Card.Text>Impossible de charger les informations du profil.</Card.Text>
          )}
        </Card.Body>
      </Card>

      <div className="repairs-section">
        <h2 className="text-center mb-4">Mes dernières réparations</h2>
        {lastRepairs.length > 0 ? (
          <Row className="repairs-list">
            {lastRepairs.map((repair) => (
              <Col key={repair.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <Card className="repair-card shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <Badge bg={repair.status === 'En cours' ? 'warning' : 'success'}>
                        {repair.status}
                      </Badge>
                    </div>
                    <Card.Text>
                      <strong>Modèle :</strong> {repair.model}<br />
                      {Array.isArray(repair.services) && repair.services.length > 0 && (
                        <div className="services mt-2">
                          <strong>Services Commandés :</strong>
                          <ul>
                            {repair.services.map((service, index) => (
                              <li key={index}>{service}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <strong>Date de commande :</strong> {new Date(repair.creationDate).toLocaleDateString()}<br />
                      {repair.repairStartDate && (
                        <>
                          <strong>Date début :</strong> {new Date(repair.repairStartDate).toLocaleDateString()}<br />
                        </>
                      )}
                      {repair.estimatedCompletionDate && (
                        <>
                          <strong>Estimation fin :</strong> {new Date(repair.estimatedCompletionDate).toLocaleDateString()}<br />
                        </>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center mt-5">
            <p>Aucune réparation récente trouvée.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountOverview;
