import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import correct de `useAuth`
import SearchComponent from '../pages/phones/SearchComponent'; // Importation du SearchComponent
import './TopMenu.scss';

const TopMenu = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth().isAuthenticated; 
  

  const handleSearchSubmit = (query) => {
    if (query.trim()) {
      navigate(`/search?query=${query}`);
    }
  };

  return (
    <header className="top-menu">
      <div className="container top-menu-container">
        {/* Logo à gauche */}
        <div className="logo">
         
              <Link to="/" className="text-logo">
                I<span className="repair-part">repair</span>It
              </Link>
            
        </div>

        {/* Barre de recherche au centre */}
        <div className="search-bar">
          <SearchComponent
            placeholder="Rechercher un téléphone..."
            onSearchSubmit={handleSearchSubmit}
          />
        </div>

        {/* Liens du menu */}
        <nav className="top-links">
          {!isAuthenticated && ( // Affiche Connexion uniquement si non authentifié
            <Link to="/acces" className="nav-link">
              Connexion
            </Link>
          )}
          <Link to="/about" className="nav-link">
            À propos
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default TopMenu;
