import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider, useAuth } from './components/AuthContext';
import { RepairProvider } from './pages/repair/RepairContext';
import { ClientProvider } from './pages/repair/ClientContext';
import RoutesConfig from "./RoutesConfig";
import SEOConfig from './components/SEOConfig'; // Importer SEOConfig

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/acces" replace />;
}

function App() {
  const routes = RoutesConfig(ProtectedRoute);

  return (
    <AuthProvider>
      <RepairProvider>
        <ClientProvider>
          <div className="App">
            <SEOConfig /> {/* Utiliser le composant SEOConfig */}
            <Header />
            <div className="container-fluid px-3 main-content">
              <Routes>
                {routes.map(({ path, element, children }, index) => (
                  <Route key={index} path={path} element={element}>
                    {children?.map((child, childIndex) => (
                      <Route key={childIndex} {...child} />
                    ))}
                  </Route>
                ))}
              </Routes>
            </div>
            <Footer />
          </div>
        </ClientProvider>
      </RepairProvider>
    </AuthProvider>
  );
}

export default App;
