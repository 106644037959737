import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import accountMenuItems from './AccountMenuData.json'; // Importation des données JSON
import './AccountMenu.scss';

const AccountMenu = ({ onClose }) => {
  return (
    <div className="account-menu-overlay">
      <div className="account-menu">
        {accountMenuItems.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className="account-link"
            onClick={onClose}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

AccountMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AccountMenu;
