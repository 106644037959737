// SEOConfig.js
import React from 'react';
import { Helmet } from 'react-helmet';

function SEOConfig() {
  return (
    <Helmet>
      <title>iRepair It - Réparation de smartphones</title>
      <meta name="description" content="Bienvenue sur iRepair It, votre expert en réparation de smartphones, tablettes et appareils électroniques." />
      <meta name="keywords" content="réparation téléphone, réparation smartphone, iRepair It" />
      <meta name="author" content="iRepair It" />

      {/* Open Graph */}
      <meta property="og:title" content="iRepair It - Réparation de smartphones" />
      <meta property="og:description" content="Bienvenue sur iRepair It, votre expert en réparation de smartphones, tablettes et appareils électroniques." />
      <meta property="og:image" content="https://irepairit.fr/images/homepage.jpg" />
      <meta property="og:url" content="https://irepairit.fr" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:locale:alternate" content="en_US" />

      {/* Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="iRepair It - Réparation de smartphones" />
      <meta name="twitter:description" content="Bienvenue sur iRepair It, votre expert en réparation de smartphones, tablettes et appareils électroniques." />
      <meta name="twitter:image" content="https://irepairit.fr/images/homepage.jpg" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://irepairit.fr" />
    </Helmet>
  );
}

export default SEOConfig;
