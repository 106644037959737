import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet"; // Importer Helmet pour la gestion du SEO
import "./RepairServicesList.scss";
import seoContent from '../../seo/seoContent'; // Importer seoContent

const RepairServicesList = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // État pour gérer l'affichage des descriptions
  const [activeService, setActiveService] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      const cacheKey = "repairServices";
      const cacheExpiryKey = "repairServicesExpiry";
      const now = new Date().getTime();

      const cachedData = localStorage.getItem(cacheKey);
      const cachedExpiry = localStorage.getItem(cacheExpiryKey);

      if (cachedData && cachedExpiry && now < parseInt(cachedExpiry, 10)) {
        // Utiliser les données en cache
        setServices(JSON.parse(cachedData));
        setLoading(false);
      } else {
        try {
          const response = await axios.get(
            "https://shoponlignebackend-production.up.railway.app/repair-options"
          );

          // Stocker les données et l'expiration dans localStorage
          localStorage.setItem(cacheKey, JSON.stringify(response.data));
          localStorage.setItem(cacheExpiryKey, (now + 24 * 60 * 60 * 1000).toString());

          setServices(response.data);
        } catch {
          setError("Erreur lors de la récupération des services.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchServices();
  }, []);

  const toggleDescription = (id) => {
    // Si le service est déjà actif, on le ferme, sinon on l'ouvre
    setActiveService(activeService === id ? null : id);
  };

  if (loading) return <p>Chargement...</p>;
  if (error) return <p>{error}</p>;

  // Récupérer le contenu SEO pour cette page
  const { title, description } = seoContent.repairServicesList;

  return (
    <div className="repair-services">
      {/* Ajouter les balises <Helmet> pour SEO */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <section className="expertise">
        <h2>Expertise en Réparation de Téléphones</h2>
        <p>
          Chez <strong>I repairIt</strong>, nous redonnons vie à vos appareils avec rapidité et fiabilité.
        </p>
      </section>

      <h1 className="services-title">Nos Services de Réparation</h1>

      <div className="services">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <h2 className="service-name" onClick={() => toggleDescription(service.id)}>
              {service.name}
            </h2>

            {/* Description du service */}
            <div
              className={`service-description ${activeService === service.id ? "show" : ""}`}
            >
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepairServicesList;
