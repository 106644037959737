import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from './MobileMenu';
import AccountMenu from './AccountMenu';
import SearchComponent from '../pages/phones/SearchComponent';
import { useAuth } from './AuthContext';
import './HeaderMobile.scss';

const HeaderMobile = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const isAuthenticated = useAuth().isAuthenticated;

  return (
    <header className="header-mobile">
      <div className="mobile-header-container">
        {/* Hamburger pour le menu principal */}
        <div className="hamburger-container" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <div className={`hamburger ${mobileMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        {/* Texte stylisé "IrepairIt" avec dégradé au centre */}
        <div className="mobile-logo">
          <Link to="/" className="text-logo">
            I<span className="repair-part">repair</span>It
          </Link>
        </div>

        {/* Icône utilisateur et Connexion */}
        <div className="account-section">
          {!isAuthenticated && (
            <Link to="/acces" className="nav-link">
              Connexion
            </Link>
          )}
          <div
            className="account-menu-icon"
            onClick={isAuthenticated ? () => setAccountMenuOpen(!accountMenuOpen) : null}
            style={{ cursor: isAuthenticated ? 'pointer' : 'default', marginLeft: '10px' }}
          >
            <FontAwesomeIcon icon={faUser} className={`user-icon ${accountMenuOpen ? 'active' : ''}`} />
          </div>
        </div>
      </div>

      {/* Barre de recherche sous le logo */}
      <div className="mobile-search-bar">
        <SearchComponent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {/* MobileMenu */}
      {mobileMenuOpen && <MobileMenu onClose={() => setMobileMenuOpen(false)} />}

      {/* AccountMenu */}
      {accountMenuOpen && <AccountMenu onClose={() => setAccountMenuOpen(false)} />}

      {/* Nouveau menu en dessous */}
      <nav className="brand-menu">
        <ul>
          <li><Link to="/repair/apple">Apple</Link></li>
          <li><Link to="/repair/samsung">Samsung</Link></li>
          <li><Link to="/repair">Autres</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderMobile;
