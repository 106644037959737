import React, { createContext, useState, useContext, useEffect } from 'react';

// Création du contexte pour la gestion des données de réparation
const RepairContext = createContext();

// Fournisseur de contexte pour gérer et mettre à jour les données de réparation
export const RepairProvider = ({ children }) => {
  const [repairData, setRepairData] = useState(() => {
    // Charger les données depuis localStorage au démarrage
    const savedData = localStorage.getItem('repairData');
    return savedData
      ? JSON.parse(savedData)
      : {
          phoneModel: '', // Modèle du téléphone
          repairType: '', // Type de réparation
          services: [], // Liste des services disponibles
          notes: '', // Notes de réparation
          status: 'En attente', // Statut initial de la réparation
          estimatedCompletionDate: '', // Date d'achèvement estimée
          selectedPhone: null, // Stocke le téléphone sélectionné
          phoneId: null, // ID du téléphone, pour référence API
          selectedServices: [], // Liste des services spécifiquement sélectionnés
          expandedService: null, // Service actuellement ouvert pour voir les détails
          ficheReparationInitiee: false, // Indicateur de début de réparation
          repairId: null, // ID de la réparation, à stocker après la création via l'API
          selectedBrand: '', // Marque sélectionnée
        };
  });

  // Sauvegarder les données dans localStorage à chaque mise à jour
  useEffect(() => {
    localStorage.setItem('repairData', JSON.stringify(repairData));
  }, [repairData]);

  // Fonction pour mettre à jour les données de réparation
  const updateRepairData = (key, value) => {
    if (typeof key === 'string') {
      setRepairData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    } else if (typeof key === 'object') {
      setRepairData((prevData) => ({
        ...prevData,
        ...key,
      }));
    }
  };

  // Fonction pour gérer la liste des services sélectionnés
  const addService = (service) => {
    if (!repairData.selectedServices.includes(service)) {
      setRepairData((prevData) => ({
        ...prevData,
        selectedServices: [...prevData.selectedServices, service],
      }));
    }
  };

  const removeService = (service) => {
    setRepairData((prevData) => ({
      ...prevData,
      selectedServices: prevData.selectedServices.filter((s) => s !== service),
    }));
  };

  const toggleService = (service) => {
    setRepairData((prevData) => {
      const alreadySelected = prevData.selectedServices.includes(service);
      return {
        ...prevData,
        selectedServices: alreadySelected
          ? prevData.selectedServices.filter((s) => s !== service)
          : [...prevData.selectedServices, service],
      };
    });
  };

  // Fonction pour mettre à jour la marque sélectionnée
  const updateSelectedBrand = (brand) => {
    setRepairData((prevData) => ({
      ...prevData,
      selectedBrand: brand,
    }));
  };

  // Fonction pour vider les données de réparation
  const clearRepairData = () => {
    console.log('clearRepairData appelé'); // Ajout d'un log pour le débogage
    const clearedData = {
      phoneModel: '',
      repairType: '',
      services: [],
      notes: '',
      status: 'En attente',
      estimatedCompletionDate: '',
      selectedPhone: null,
      phoneId: null,
      selectedServices: [],
      expandedService: null,
      ficheReparationInitiee: false,
      repairId: null,
      selectedBrand: '',
    };
    setRepairData(clearedData);
    localStorage.setItem('repairData', JSON.stringify(clearedData));
  };

  return (
    <RepairContext.Provider
      value={{
        repairData,
        updateRepairData,
        clearRepairData,
        updateSelectedBrand,
        addService,
        removeService,
        toggleService,
      }}
    >
      {children}
    </RepairContext.Provider>
  );
};

// Hook personnalisé pour accéder au contexte
export const useRepair = () => useContext(RepairContext);
