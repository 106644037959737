const defaultOgImage = `${process.env.PUBLIC_URL}/images/default.jpg`; // Image par défaut si aucune n'est spécifiée
const baseUrl = process.env.REACT_APP_BASE_URL || "https://example.com"; // URL de fallback si l'environnement n'est pas défini

const seoContent = {
  homepage: {
    title: "Accueil - iRepair It",
    description:
      "Bienvenue sur iRepair It, votre expert en réparation de smartphones. Nous réparons tous types de téléphones, y compris Apple, Samsung et Google.",
    ogImage: `${process.env.PUBLIC_URL}/images/homepage.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}`,
    ogType: "website",
    twitterCard: "summary_large_image",
    keywords: "réparation téléphone, réparation smartphone, iRepair It",
    canonicalUrl: `${baseUrl}`,
    ogLocale: "fr_FR",
    ogLocaleAlternate: ["en_US", "es_ES"],
    ogSiteName: "iRepair It",
  },
  phoneList: (model) => ({
    title: `Réparation du téléphone ${model} - iRepair It`,
    description: `Découvrez notre expertise en réparation du téléphone ${model}. Nous proposons des services rapides et fiables pour tous les modèles ${model}.`,
    ogImage: `${process.env.PUBLIC_URL}/images/${model.toLowerCase().replace(/\s+/g, '-')}.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}/phone-list/${encodeURIComponent(model)}`,
    ogType: "product",
    twitterCard: "summary_large_image",
    keywords: `réparation ${model}, smartphone ${model}, réparation téléphone ${model}`,
    canonicalUrl: `${baseUrl}/phone-list/${encodeURIComponent(model)}`,
    ogLocale: "fr_FR",
    ogLocaleAlternate: ["en_US", "es_ES"],
    ogSiteName: "iRepair It",
  }),
  repairServicesList: {
    title: "Nos Services de Réparation - iRepair It",
    description:
      "Découvrez les services de réparation que nous proposons pour vos smartphones. De la réparation de l'écran aux problèmes de batterie, nous avons l'expertise pour remettre vos appareils en état.",
    ogImage: `${process.env.PUBLIC_URL}/images/repair-services.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}/repair-services`,
    ogType: "website",
    twitterCard: "summary_large_image",
    keywords: "réparation téléphone, réparation écran, réparation batterie",
    canonicalUrl: `${baseUrl}/repair-services`,
    ogLocale: "fr_FR",
    ogSiteName: "iRepair It",
  },
  about: {
    title: "À propos de iRepair It - Réparation de Téléphones",
    description:
      "Chez iRepair It, nous offrons un service rapide, fiable et écologique pour réparer vos téléphones. Nos experts utilisent des pièces de qualité pour garantir une réparation durable.",
    ogImage: `${process.env.PUBLIC_URL}/images/about-us.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}/about`,
    ogType: "website",
    twitterCard: "summary_large_image",
    keywords: "à propos, iRepair It, réparation smartphone",
    canonicalUrl: `${baseUrl}/about`,
    ogLocale: "fr_FR",
    ogSiteName: "iRepair It",
  },
  contact: {
    title: "Contactez-nous - iRepair It",
    description:
      "Vous avez des questions ou souhaitez en savoir plus sur nos services de réparation ? Contactez-nous dès aujourd'hui via notre formulaire de contact.",
    ogImage: `${process.env.PUBLIC_URL}/images/contact.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}/contact`,
    ogType: "website",
    twitterCard: "summary_large_image",
    keywords: "contact iRepair It, service client réparation",
    canonicalUrl: `${baseUrl}/contact`,
    ogLocale: "fr_FR",
    ogSiteName: "iRepair It",
  },
  accessPage: {
    title: "Connexion et Inscription - iRepair It",
    description:
      "Accédez à votre compte ou inscrivez-vous pour profiter des services de réparation de téléphones. Rejoignez iRepair It pour une expérience rapide et fiable.",
    ogImage: `${process.env.PUBLIC_URL}/images/access.jpg` || defaultOgImage,
    ogUrl: `${baseUrl}/access`,
    ogType: "website",
    twitterCard: "summary",
    keywords: "connexion, inscription, iRepair It",
    canonicalUrl: `${baseUrl}/access`,
    ogLocale: "fr_FR",
    ogSiteName: "iRepair It",
  },
};

export default seoContent;
